import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Script } from 'nordic/script';

const initScripts = {
  Slider: 'initSlider',
  SliderEditable: 'initSlider',
};

const CustomComponent = ({ customization, componentProperties }) => {
  useEffect(() => {
    if (window && window[initScripts[componentProperties]]) {
      window[initScripts[componentProperties]]();
    }
  }, [customization]);

  return (
    <>
      {parse(customization?.html,
        {
          replace(domNode) {
            // replace local scripts for map scripts
            if (domNode.name === 'script' && domNode.attribs?.local === 'true') {
              const jsKey = domNode.attribs?.src;
              const jsScript = customization.javascript?.[jsKey];
              if (jsScript) {
                return <Script src={jsScript} />;
              }

              return <></>;
            }

            return domNode;
          },
        })}
    </>
  );
};

CustomComponent.propTypes = {
  componentProperties: PropTypes.string,
  customization: PropTypes.shape({
    html: PropTypes.string.isRequired,
    javascript: PropTypes.shape({}),
  }).isRequired,
};

export default CustomComponent;
