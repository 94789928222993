const React = require('react');

const PropTypes = require('prop-types');
const { Head } = require('nordic/head');

const { MelidataTrack } = require('nordic/melidata/melidata-track');
const { Style } = require('nordic/style');
const { useI18n } = require('nordic/i18n');
const Appearance = require('@mshops-web-core/appearance').default;

const canUseDOM = require('../../../utils/enabledDOM');

const HomeLayout = require('./HomeLayout');
const PreloadImages = require('./PreloadImages').default;
const RenderTree = require('../../../appearance/renderTree').default;
const nonEditableComponentList = require('./nonEditables');

const { RenderProvider } = require('../context');

const { HOME, CUSTOM_CONTENT_PAGE } = require('../../../utils/constants/sections');
const { CartProvider } = require('../../marketplaceHome/context/cart');

const { FreeShippingProvider } = require('../../marketplaceHome/context/freeShipping');

const { useEffect } = React;

function HomeView({
  siteId,
  lowEnd,
  deviceType,
  shopModel = {
    appearance: {
      properties: {},
    },
  },
  config,
  section,
  siteKey,
  apiBasePath,
  hideModalSubscription,
  isHubPreview,
  isRobotsScope,
  uuidVersion = '',
  features,
  csrfToken,
  preloadImages,
  experiments,
}) {
  // eslint-disable-next-line
  const { i18n } = useI18n();
  const { appearance, fiscal_data: fiscalData, shop, contact, carts_info: cartInfo } = shopModel;
  const { currency, title } = appearance.properties;
  const { restClient } = config;

  const appearanceService = new Appearance(appearance);
  const layoutTheme = appearanceService.getComponentProperty('Document', 'theme');
  const metaTags = appearanceService.getComponentProperty('Document', 'meta_tags');
  const experimentAddToCart = experiments ? experiments['add-to-cart/product-card-button'] : 38774;
  const isAddToCartDisabled = features?.includes('add-to-cart-custom-disabled');

  const page = (section === HOME) ? 'MAIN' : section.toUpperCase();

  useEffect(() => {
    if (canUseDOM() && window.tracking_mediator) {
      window.tracking_mediator(
        'dispatch',
        `page:${section}`,
        shopModel.appearance,
      );
      window.tracking_mediator(
        'dispatch',
        `gtm:${section}`,
        { page, section },
      );
    }
  }, []);

  const renderProviderInitialValue = {
    theme: layoutTheme,
    fiscalData,
    contact,
    socialNetworks: shop?.social_networks,
    appearance: new Appearance(appearance),
    initial: new Appearance(appearance),
    device: deviceType,
    apiBasePath,
    draftMode: !!(uuidVersion),
    numberStaticComponentsTop: 0,
    shopModel,
    currency,
    siteId,
    lowEnd,
    siteKey,
    restClient,
    hideModalSubscription,
    isHubPreview,
    features,
    cartInfo,
    csrfToken,
    withCardAction: experimentAddToCart === 38775,
  };

  const layout = appearanceService.getTree()?.children[1]?.children;
  const hasTabbedCarousel = !!layout.find(component => component.component === 'TabbedCarousel');
  const hasCollectionGrid = !!layout.find(component => component.component === 'CollectionGrid');

  const sliders = appearance.children
    .find(child => child.id === 'layout')
    .children.filter(child => child.id === 'slider');

  const eventData = {
    seller_id: shopModel.shop?.id,
    has_tabbed_carrousel: hasTabbedCarousel,
    has_collection_grid: hasCollectionGrid,
    has_slider_v2: sliders.some(slider => slider.properties.version === 'v2'),
    has_slider_v1: sliders.some(slider => slider.properties.version === 'v1'),
  };

  return (
    <>
      <MelidataTrack
        path={section === CUSTOM_CONTENT_PAGE ? '/shop_custom_content' : '/home'}
        event_data={eventData}
        experiments={(isRobotsScope || isAddToCartDisabled) ? undefined : experiments}
      />
      <Head>
        <title>{title}</title>
        {(metaTags && metaTags.length)
          ? metaTags.map((meta) => <meta key={meta.type} name={meta.type} content={meta.content} />)
          : null
        }
        <PreloadImages images={preloadImages} />
      </Head>
      <Style href={`${layoutTheme}/home-${layoutTheme}-${deviceType}.css`} />
      <Style href={`home-${deviceType}.css`} />

      <RenderProvider value={renderProviderInitialValue}>
        <CartProvider
          value={{
            addedItems: cartInfo?.items?.active || [],
          }}
        >
          <FreeShippingProvider>
            <HomeLayout
              section={section}
              isCustomHTML={shopModel?.appearance?.properties?.custom}
              shopName={shopModel?.shop?.name}
            >
              <RenderTree componentsList={nonEditableComponentList(features, section)} i18n={i18n} />
            </HomeLayout>
          </FreeShippingProvider>
        </CartProvider>
      </RenderProvider>
    </>
  );
}

HomeView.propTypes = {
  siteId: PropTypes.string.isRequired,
  lowEnd: PropTypes.bool.isRequired,
  deviceType: PropTypes.string.isRequired,
  shopModel: PropTypes.shape(),
  config: PropTypes.shape().isRequired,
  section: PropTypes.string.isRequired,
  siteKey: PropTypes.string.isRequired,
  apiBasePath: PropTypes.string.isRequired,
  hideModalSubscription: PropTypes.bool.isRequired,
  isHubPreview: PropTypes.bool.isRequired,
  uuidVersion: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  csrfToken: PropTypes.string.isRequired,
  preloadImages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

module.exports = HomeView;
